import React from 'react'
import LabRegistrationPage from '../../../Component/LabCenters/LabRegistration/Labregistration'
const Labregistration = () => {
  return (
    <div>
      <LabRegistrationPage/>
    </div>
  )
}

export default Labregistration