import React from 'react'
import Labdetail from '../../../Component/AdminsComponents/ViewLabDetails/LabViewPage'
const Labdetailpage = () => {
  return (
    <div>
        <Labdetail/>
    </div>
  )
}

export default Labdetailpage