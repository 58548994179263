import React from "react";
import Categoriespage from "../../../Component/AdminsComponents/TestCategories/Testcategories";

const Testcategories = () => {
  return (
    <div>
      <Categoriespage />
    </div>
  );
};

export default Testcategories;
