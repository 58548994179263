import React from "react";
import CenterDetail from "../../../Component/AdminsComponents/ViewDetail/ViewDetailPage";
const Centerdetailpage = () => {
  return (
    <div>
      <CenterDetail />
    </div>
  );
};

export default Centerdetailpage;
