import React from 'react'
import Header from '../../Component/Header/Header'
import Usersignform from '../../Component/UserSignUp/Usersignup'
const Usersignup = () => {
  return (
    <div>
      <Header namenav="Sign Up"/>
      <Usersignform/>
    </div>
  )
}

export default Usersignup