import React from 'react'
import LabCategoryPage from '../../../Component/AdminsComponents/LabCategories/FinalCategory'

const LabCategory = () => {
  return (
    <div>
        <LabCategoryPage/>
        </div>
  )
}

export default LabCategory