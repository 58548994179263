import React from 'react'
import Admindashboard from '../../../Component/AdminsComponents/AdminPanel/adminPanel'

const AdminPanel = () => {
  return (
    <div>
        <Admindashboard/>
    </div>
  )
}

export default AdminPanel