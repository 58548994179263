import React from 'react'
import ScanregistrationPage from '../../../Component/Centers/ScanRegistration/Scanregistration'

const Scanregistration = () => {
  return (
    <div>
        <ScanregistrationPage/>
    </div>
  )
}

export default Scanregistration