import React from 'react'
import UserDetailsPage from '../../../Component/AdminsComponents/UserDetails/Userdetails'

const Userdetails = () => {
  return (
    <div>
        <UserDetailsPage/>
    </div>
  )
}

export default Userdetails