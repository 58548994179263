import React from 'react'
import OwnerDetailsPage from '../../../Component/AdminsComponents/OwnersDetails/OwnerDetails'

const Ownersdetails = () => {
  return (
    <div>
        <OwnerDetailsPage/>
    </div>
  )
}

export default Ownersdetails