import React from 'react'
import TestPage from '../../../Component/AdminsComponents/TestDetails/Testdetails'
const Testdetails = () => {
  return (
    <div>
        <TestPage/>
    </div>
  )
}

export default Testdetails