import React from 'react'
import Adminloginform from '../../../Component/AdminsComponents/Adminlogin/adminLogin'

const Adminlogin = () => {
  return (
    <div>
        <Adminloginform/>
    </div>
  )
}

export default Adminlogin