import React from 'react'
import Locations from '../../../Component/AdminsComponents/AddLocation/Locations'

const Addlocation = () => {
  return (
    <div>
        <Locations/>
    </div>
  )
}

export default Addlocation