import React from 'react'
import PendingList from '../../../Component/AdminsComponents/ScanPendingList/ScanPendingList'
const ScanPendingList = () => {
  return (
    <div>
      <PendingList/>
    </div>
  )
}

export default ScanPendingList